import React from "react";
import "./cloudsBG.css";
function Clouds() {
  return (
    <div className="cloudContainer">
      <div id="background-wrap">
        <div className="x1">
          <div className="cloud"></div>
        </div>

        <div className="x2">
          <div className="cloud"></div>
        </div>

        <div className="x3">
          <div className="cloud"></div>
        </div>

        <div className="x4">
          <div className="cloud"></div>
        </div>

        <div className="x5">
          <div className="cloud"></div>
        </div>

        <div className="x6">
          <div className="cloud"></div>
        </div>

        <div className="x7">
          <div className="cloud"></div>
        </div>
      </div>
    </div>
  );
}

export default Clouds;
